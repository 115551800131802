<template>
  <div class="card mt-5">
    <div class="font-medium text-3xl text-900 mb-3">Resumo da Compra</div>
    <div class="grid grid-nogutter border-top-1 surface-border pt-2">
      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Nome</div>
        <div class="text-900">{{despesa.name}}</div>
      </div>
      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Data da Compra</div>
        <div class="text-900">{{ despesa.dtPurchase}}</div>
      </div>
      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Tipo de Compra</div>
        <div class="text-900">{{ despesa.inventoryType }}</div>
      </div>
      <div class="col-12 p-3" v-if="despesa.description">
        <div class="text-500 font-medium mb-2">Descrição</div>
        <div class="text-900 line-height-3">{{ despesa.description }}</div>
      </div>
      <div class="col-12 p-3">
        <Panel header="Lista de Produtos" class="mt-5">
          <DataTable :value="despesa.expenditures" dataKey="id" responsiveLayout="scroll" >
            <Column field="product.name" header="Produto"></Column>
            <Column field="amount" header="Quantidade"></Column>
            <Column field="value" header="Valor Unitário">
              <template #body="slotProps">
                <span class="p-column-title">Valor Unitário</span>
                {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(slotProps.data.value)}}
              </template>
            </Column>
            <Column field="total" header="Total" align="right" header-style="text-align: right">
              <template #body="slotProps">
                <div style="text-align:right; justify-content: space-between">
                  {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(slotProps.data.total)}}
                </div>
              </template>
            </Column>
            <template #footer>
              <div style="text-align:right; justify-content: space-between">
                <span class="p-column-title text-center">Total: </span>
                {{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(despesa.total)}}
              </div>
            </template>
          </DataTable>
        </Panel>

        <Toolbar class="mt-3">
          <template v-slot:end>
            <div class="my-2">
              <Button label="Anterior" icon="pi pi-angle-left" icon-pos="left" @click="prevPage()" class="mr-2"/>
              <Button label="Finalizar" icon="pi pi-save" icon-pos="right" @click="complete()" />
            </div>
          </template>
        </Toolbar>

      </div>
    </div>
  </div>
</template>

<script>

import InventoryService from "@/service/InventoryService";
import moment from "moment";

export default {
  name: "despesa-step3",
  props: {
    formData: Object
  },
  data() {
    return {
      despesaId: null,
      despesa: this.formData,
      inventoryService: null
    }
  },
  created() {
    this.inventoryService = new InventoryService();
    this.moment = moment;
    moment.locale('pt-BR');
  },
  mounted() {
    this.loadEdit();
  },
  methods: {
    async loadEdit() {
      this.despesaId = this.$route.query.id;
      if(this.despesaId) {
        await this.findById();
      }
    },

    async findById() {
      const response = await this.inventoryService.findById(this.despesaId);
      if(response.status === 200) {
        this.despesa = response.data;
      }
    },
    prevPage() {
      this.$emit('prev-page', {pageIndex: 2, despesaId: this.despesaId});
    },

    complete() {
      this.$emit('complete');
    }
  }
}
</script>

<style scoped>

</style>
